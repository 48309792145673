<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle de la tabla: {{ this.tablaNombre }}</v-card-title
      >
      <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
        <v-container class="pt-0">
          <v-row justify="end">
            <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn
                  value="CONVENIOS"
                  :disabled="mostrarTablaPracticas"
                  @click="changeTab(1)"
                  >Prácticas</v-btn
                >
                <v-btn
                  value="PARENTESCOS"
                  :disabled="mostrarTablaAranceles"
                  @click="changeTab(2)"
                >
                  Aranceles</v-btn
                >
                <v-btn
                  value="COBERTURA ESPECIAL"
                  :disabled="mostrarTablaPropietarios"
                  @click="changeTab(3)"
                  >Propietarios</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div v-if="mostrarTablaPracticas">
        <v-card>
          <v-data-table
            :headers="headersPractica"
            :items="detallePractica"
            class="elevation-1"
            :search="search"
            :loading="isLoadingDetalle"
            :page.sync="pagination"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <!-- Tabla aranceles -->
      <div v-if="mostrarTablaAranceles">
        <v-card-title class="pl-1 primary--text"
          >Conjunto de aranceles: {{ this.conjuntoArancel }}</v-card-title
        >
        <v-card>
          <v-data-table
            :headers="headersAranceles"
            :items="arancelesTabla"
            class="elevation-1"
            :loading="isLoadingAranceles"
            :page.sync="pagination"
          >
            <template v-slot:[`item.valor`]="{ item }">
              <span>{{ addDecimals(item.valor) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <!-- Tabla propietarios -->
      <div v-if="mostrarTablaPropietarios">
        <v-card>
          <v-data-table
            :headers="headersPropietarios"
            :items="propietariosTabla"
            class="elevation-1"
            :page.sync="pagination"
          >
          </v-data-table>
        </v-card>
      </div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" md="3" class="mt-3 text-right py-1" align="end">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                @click="exportTabla()"
              >
                Exportar tabla
              </v-btn>
            </template>
            <span>Exportar tabla en formato importación</span>
          </v-tooltip>
        </v-col >
        <v-col cols="1" md="2" class="mt-3 text-right py-1" align="end">
          <v-btn outlined @click="closeModal"> Cerrar </v-btn>
          </v-col
        >
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "VerTablaConsulta",
  props: {
    tablaId: {
      type: Number,
      required: true
    },
    tablaNombre: {
      type: String
    },
    ctaId: {
      type: Number
    }
  },
  data: () => ({
    isLoadingDetalle: false,
    searchIcon: enums.icons.SEARCH,
    search: "",
    isLoadingPropietarios: false,
    isLoadingAranceles: false,
    mostrarTablaPracticas: true,
    mostrarTablaAranceles: false,
    mostrarTablaPropietarios: false,
    text: "",
    detallePractica: [],
    headersPractica: [
      {
        text: "Nomenclador",
        value: "nomenclador",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false,
        align: "end"
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false,
        align: "end"
      },
      {
        text: "Área",
        value: "area",
        sortable: false,
        align: "end"
      },
      {
        text: "Especialista",
        value: "especialidad",
        sortable: false,
        align: "end"
      },
      {
        text: "Anestesista",
        value: "anestesia",
        sortable: false
      },
      {
        text: "Ayudante",
        value: "ayudante",
        sortable: false,
        align: "end"
      },
      {
        text: "Gastos",
        value: "gastos",
        sortable: false,
        align: "end"
      }
    ],
    propietariosTabla: [],
    headersPropietarios: [
      {
        text: "Código de agente",
        value: "agenteId",
        sortable: false
      },
      {
        text: "Nombre de agente",
        value: "agenteNombre",
        sortable: false
      },
      {
        text: "Tipo de prestador",
        value: "agenteTipo",
        sortable: false
      }
    ],
    arancelesTabla: [],
    headersAranceles: [
      {
        text: "Unidad",
        value: "unidad",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        sortable: false,
        align: "end"
      }
    ],
    ctaFacturacionName: "",
    conjuntoArancel: "",
    allowedActions: null,
    pagination: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setDetalleProceso();
    this.setVerPropietario();
    this.setVerAranceles();
    this.setName();
  },
  methods: {
    ...mapActions({
      getTablaFacturacionDetalle: "prestadores/getTablaFacturacionDetalle",
      getPropietariosTabla: "prestadores/getPropietariosTabla",
      getTablaFacturacionAranceles: "prestadores/getTablaFacturacionAranceles",
      getNombreCtaFacturacion: "prestadores/getNombreCtaFacturacion",
      exportTablaFacturacionImportacion:
        "prestadores/exportTablaFacturacionImportacion"
    }),
    async setName() {
      const data = await this.getNombreCtaFacturacion(this.tablaId);
      this.ctaFacturacionName = data;
    },
    async setVerPropietario() {
      this.isLoadingPropietarios = true;
      const data = await this.getPropietariosTabla(this.tablaId);
      this.propietariosTabla = data;
      this.isLoadingPropietarios = false;
    },
    async setVerAranceles() {
      this.isLoadingAranceles = true;
      const data = await this.getTablaFacturacionAranceles(this.tablaId);
      this.conjuntoArancel = data.nombre;
      this.arancelesTabla = data.lista;
      this.isLoadingAranceles = false;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async setDetalleProceso() {
      this.isLoadingDetalle = true;
      const data = await this.getTablaFacturacionDetalle(this.ctaId);
      this.detallePractica = data;
      this.isLoadingDetalle = false;
    },
    async exportTabla() {
      const data = await this.exportTablaFacturacionImportacion(this.tablaId);
      this.exportarTabla = data;
      this.exportTablaImport()
    },
    exportTablaImport() {
      let result = [];
      this.exportarTabla?.forEach(x =>
        result.push({
          ["Nomenclador"]: x.nomenclador,
          ["Codigo desde"]: x.codigoDesde,
          ["Codigo hasta"]: x.codigoHasta,
          ["Area"]: x.area,
          ["Importe especialista"]: x.ctaEspecialista,
          ["Importe ayudante"]: x.ctaAyudante,
          ["Importe anestesista"]: x.ctaAnestesista,
          ["Importe gastos"]: x.ctaGastos,
          ["Unidad especialista"]: x.uniEspecialista,
          ["Unidad ayudante"]: x.uniAyudante,
          ["Unidad anestesista"]: x.uniAnestesista,
          ["Unidad gastos"]: x.uniGastos,
          
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Formato importación");
    },
    closeModal() {
      this.$emit("closeModalVerTabla");
    },
    changeTab(num) {
      switch (num) {
        case 1:
          this.mostrarTablaPracticas = true;
          this.mostrarTablaAranceles = false;
          this.mostrarTablaPropietarios = false;
          break;
        case 2:
          this.mostrarTablaPracticas = false;
          this.mostrarTablaAranceles = true;
          this.mostrarTablaPropietarios = false;
          break;
        case 3:
          this.mostrarTablaPracticas = false;
          this.mostrarTablaAranceles = false;
          this.mostrarTablaPropietarios = true;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
